import React from 'react'
import { HighlightBoldText } from '@shopper/ui/src/components/text/new'
import { Button } from 'tamagui'

export interface ApplyFiltersButtonProps {
  onPress?: () => void
  disabled?: boolean
  children: React.ReactNode
}

export const RemoveFiltersButton: React.FC<ApplyFiltersButtonProps> = ({
  children,
  onPress,
  disabled,
}) => {
  const backgroundColor = '$pureWhite'
  const borderColor = disabled ? '$midToneGray' : '$primary1000'
  const textColor = borderColor

  return (
    <Button
      onPress={onPress}
      flexGrow={1}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      borderRadius={10}
      borderWidth={1}
      hoverStyle={{
        backgroundColor,
        borderColor,
      }}
      pressStyle={{
        backgroundColor,
        borderColor,
      }}
      focusVisibleStyle={{
        outlineStyle: 'none',
      }}
      minHeight={45}
      alignItems="center"
    >
      <HighlightBoldText color={textColor}>{children}</HighlightBoldText>
    </Button>
  )
}
