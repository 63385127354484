import React from 'react'
import BaseGridColumn from '@shopper/ui/src/components/BaseGrid/Column'
import BaseGridRow from '@shopper/ui/src/components/BaseGrid/Row'
import type { YStackProps } from 'tamagui'
import { YStack } from 'tamagui'

interface BaseGridProps {
  stackProps?: YStackProps
  children: React.ReactNode
}

const BaseGrid: React.FC<BaseGridProps> & {
  Row: typeof BaseGridRow
  Column: typeof BaseGridColumn
} = ({ children, stackProps }) => {
  return (
    <YStack {...stackProps}>
      <YStack marginTop={12}>{children}</YStack>
    </YStack>
  )
}

BaseGrid.Row = BaseGridRow
BaseGrid.Column = BaseGridColumn

export default BaseGrid
