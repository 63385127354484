import React, { useContext } from 'react'
import { FilterOverlayContext } from '@shopper/app/components/Feed/FilterBar/Overlay/context'
import { CategoriesSeeMoreDisplay } from './CategoriesSeeMoreDisplay'
import { CategoriesSliderDisplay } from './CategoriesSliderDisplay'

const DefaultCategoriesBar: React.FC<object> = () => {
  const { categories } = useContext(FilterOverlayContext)
  if (categories.length === 0) {
    return <React.Fragment />
  }

  if (categories.length < 10) {
    return <CategoriesSliderDisplay />
  }

  return <CategoriesSeeMoreDisplay />
}

export default DefaultCategoriesBar
