import React, { useContext, useEffect, useState } from 'react'
import { OverlaySheet } from '@shopper/app/components/Feed/FilterBar/Overlay/components'
import { FilterOverlayContext } from '@shopper/app/components/Feed/FilterBar/Overlay/context'

const FeedFilterBarOverlay: React.FC = () => {
  const { isVisibleOverlay, setIsVisibleOverlay } = useContext(FilterOverlayContext)

  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false)

  useEffect(() => {
    if (isVisibleOverlay) {
      window.history.pushState(null, '', window.location.href)
      setShouldBlockNavigation(true)
    }
  }, [isVisibleOverlay])

  useEffect(() => {
    const handlePopState = (): void => {
      if (shouldBlockNavigation) {
        setIsVisibleOverlay(false)
        setShouldBlockNavigation(false)
      }
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [shouldBlockNavigation, setIsVisibleOverlay])

  return <OverlaySheet isVisible={isVisibleOverlay} setIsVisible={setIsVisibleOverlay} />
}

export default FeedFilterBarOverlay
