import React, { useContext } from 'react'
import { FilterOverlayContext } from '@shopper/app/components/Feed/FilterBar/Overlay/context'
import { FILTER_OVERLAY_OPTION_GROUP } from '@shopper/app/components/Feed/FilterBar/Overlay/context/constants'
import { Stack } from '@shopper/ui/src'
import { HighlightBoldText } from '@shopper/ui/src/components/text/new'
import { X } from '@tamagui/lucide-icons'
import isNil from 'lodash.isnil'
import { GetProductsFilterType } from '@centrito/api/shared/enums'

export const OverlayHeader: React.FC = () => {
  const { currentFilterDrawer, setIsVisibleOverlay } = useContext(FilterOverlayContext)

  const getHeaderText = (): string => {
    if (isNil(currentFilterDrawer)) {
      return 'Todos los filtros'
    }

    if (currentFilterDrawer === GetProductsFilterType.SORT_TYPE) {
      return 'Ordenar por'
    }

    return `Filtrar por ${FILTER_OVERLAY_OPTION_GROUP[currentFilterDrawer]?.optionGroupName.toLowerCase()}`
  }

  const headerText = getHeaderText()

  return (
    <Stack
      flexDirection="row"
      paddingTop={10}
      alignItems="center"
      paddingHorizontal={15}
      marginBottom={20}
    >
      <HighlightBoldText color="$pureBlack">{headerText}</HighlightBoldText>
      <Stack marginLeft="auto" onPress={(): void => setIsVisibleOverlay(false)}>
        <X size={24} />
      </Stack>
    </Stack>
  )
}
