import JsonURL from '@jsonurl/jsonurl'
import type { GetProductsPublicClient } from '@shopper/app/utils/services/catalog/getProducts'
import type { GetProducts } from '@centrito/api/shared/catalog'

export default function (
  this: GetProductsPublicClient,
  filters: GetProducts.Filters,
): GetProducts.FiltersQueryParam {
  if (Object.keys(filters).length === 0) return {}
  const f = JsonURL.stringify(filters)
  return { f }
}
