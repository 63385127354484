import { getColorKeyFromName } from '@shopper/app/utils/components/FeedView/ColorBubble'
import { type GetProducts } from '@centrito/api/shared/catalog'
import { GetProductsFilterType } from '@centrito/api/shared/enums'

const getIsChecked = (
  currentFilters: GetProducts.Filters,
  filterItem: GetProducts.FilterItem,
): boolean => {
  if (filterItem.type === GetProductsFilterType.BRAND_ID && typeof filterItem.value === 'string') {
    const brandId = filterItem.value
    const brandFilterValue = currentFilters?.[GetProductsFilterType.BRAND_ID] ?? []
    return brandFilterValue.includes(brandId)
  }

  if (filterItem.type === GetProductsFilterType.CATEGORY && typeof filterItem.value === 'string') {
    const category = filterItem.value
    const categoryFilterValue = currentFilters?.[GetProductsFilterType.CATEGORY] ?? []
    return categoryFilterValue.includes(category)
  }

  if (filterItem.type === GetProductsFilterType.COLOR && typeof filterItem.value === 'string') {
    const color = filterItem.value
    const colorFilterValue = currentFilters?.[GetProductsFilterType.COLOR] ?? []
    return colorFilterValue.includes(getColorKeyFromName(color) ?? '')
  }

  if (filterItem.type === GetProductsFilterType.SIZE && typeof filterItem.value === 'string') {
    const size = filterItem.value
    const sizeFilterValue = currentFilters?.[GetProductsFilterType.SIZE] ?? []
    return sizeFilterValue.includes(size || '')
  }

  if (filterItem.type === GetProductsFilterType.SUPPLIER && typeof filterItem.value === 'string') {
    const merchant = filterItem.value
    const merchantFilterValue = currentFilters?.[GetProductsFilterType.SUPPLIER] ?? []
    return merchantFilterValue.includes(merchant)
  }

  return currentFilters?.[filterItem.type] === filterItem.value
}

export default getIsChecked
