import React, { useContext } from 'react'
import { FilterOverlayContext } from '@shopper/app/components/Feed/FilterBar/Overlay/context'
import type { FilterOverlay } from '@shopper/app/components/Feed/FilterBar/Overlay/context/types'
import { RadioGroup, YStack } from '@shopper/ui/src'
import { SortOptionItem } from './Item'

interface SortGroupProps {
  options: FilterOverlay.OptionItem[]
}

export const SortGroup: React.FC<SortGroupProps> = ({ options }) => {
  const { changeOptionItemIsChecked } = useContext(FilterOverlayContext)
  const selectedValue = options.find((opt) => opt.isChecked)?._filterItem.value?.toString() || ''

  const handleValueChange = (newValue: string): void => {
    const targetOption = options.find((opt) => opt._filterItem.value === newValue)
    if (targetOption) {
      changeOptionItemIsChecked(targetOption)
    }
  }

  return (
    <RadioGroup value={selectedValue} onValueChange={handleValueChange}>
      <YStack flex={1} paddingVertical={10} gap={5}>
        {options.map((optionItem) => (
          <SortOptionItem key={optionItem.name} optionItem={optionItem} />
        ))}
      </YStack>
    </RadioGroup>
  )
}
