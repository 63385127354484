import { type Dispatch, type SetStateAction } from 'react'
import { useFiltersQuery } from '@shopper/app/utils/hooks/useFeedProducts/useFiltersQuery'
import isNil from 'lodash.isnil'
import omit from 'lodash.omit'
import { type GetProducts } from '@centrito/api/shared/catalog'
import { COLOR_LIBRARY } from '@centrito/api/shared/colors'
import { GetProductsFilterType, ProductSortType } from '@centrito/api/shared/enums'
import { type FilterOverlay } from './types'

const optionItemsToFilters: {
  [K in FilterOverlay.OverlayFilterType]: (
    checkedOptionItems: FilterOverlay.OptionItem<K>[],
  ) => GetProducts.Filters
} = {
  [GetProductsFilterType.BRAND_ID]: (checkedOptionItems) => {
    const brandIds = checkedOptionItems.map((optionItem) => optionItem._filterItem.value).flat()

    if (brandIds.length === 0) {
      return {}
    }

    return {
      [GetProductsFilterType.BRAND_ID]: brandIds,
    }
  },
  [GetProductsFilterType.CATEGORY]: (checkedOptionItems) => {
    const categories = checkedOptionItems.map((optionItem) => optionItem._filterItem.value).flat()

    if (categories.length === 0) {
      return {}
    }

    return {
      [GetProductsFilterType.CATEGORY]: categories,
    }
  },
  [GetProductsFilterType.COLOR]: (checkedOptionItems) => {
    const colors = checkedOptionItems
      .map((optionItem) =>
        COLOR_LIBRARY.find((color) => color.name === optionItem._filterItem.value),
      )
      .map((colorData) => colorData?.key || 'blanco')
      .flat()

    if (colors.length === 0) {
      return {}
    }

    return {
      [GetProductsFilterType.COLOR]: colors,
    }
  },
  [GetProductsFilterType.SIZE]: (checkedOptionItems) => {
    const sizes = checkedOptionItems.map((size) => size.name)

    if (sizes.length === 0) {
      return {}
    }

    return {
      [GetProductsFilterType.SIZE]: sizes,
    }
  },
  [GetProductsFilterType.PRICE_RANGE]: (optionItems) => {
    if (optionItems.length === 0) {
      return {}
    }

    return {
      [GetProductsFilterType.PRICE_RANGE]: optionItems[0]._filterItem.value,
    }
  },
  [GetProductsFilterType.SUPPLIER]: (checkedOptionItems) => {
    const merchants = checkedOptionItems.map((optionItem) => optionItem._filterItem.value).flat()
    if (merchants.length === 0) {
      return {}
    }

    return {
      [GetProductsFilterType.SUPPLIER]: merchants,
    }
  },
  [GetProductsFilterType.SORT_TYPE]: (checkedOptionItems) => {
    if (checkedOptionItems.length === 0 || isNil(checkedOptionItems[0]?._filterItem?.value)) {
      return {}
    }
    const sortType = checkedOptionItems[0]._filterItem.value

    if (sortType === ProductSortType.NONE) {
      return {}
    }

    return {
      [GetProductsFilterType.SORT_TYPE]: sortType,
    }
  },
}

const optionsToFilters = (
  options: FilterOverlay.Options,
  currentFilters: GetProducts.Filters,
  priceRange?: { low: number; high: number; boundaryLow: number; boundaryHigh: number },
): GetProducts.Filters => {
  const brandsOptions =
    options[GetProductsFilterType.BRAND_ID]?.filter((optionItem) => optionItem.isChecked) ?? []
  const categoryOptions =
    options[GetProductsFilterType.CATEGORY]?.filter((optionItem) => optionItem.isChecked) ?? []
  const colorOptions =
    options[GetProductsFilterType.COLOR]?.filter((optionItem) => optionItem.isChecked) ?? []
  const sizeOptions =
    options[GetProductsFilterType.SIZE]?.filter((optionItem) => optionItem.isChecked) ?? []

  const priceOptions =
    !isNil(priceRange) &&
    !(priceRange.high === priceRange.boundaryHigh && priceRange.low === priceRange.boundaryLow)
      ? [
          {
            name: 'price_range',
            isChecked: true,
            _filterItem: {
              type: GetProductsFilterType.PRICE_RANGE,
              value: omit(priceRange, ['boundaryLow', 'boundaryHigh']),
            },
          },
        ]
      : []

  const merchantOptions =
    options[GetProductsFilterType.SUPPLIER]?.filter((optionItem) => optionItem.isChecked) || []

  const sortOptions =
    options[GetProductsFilterType.SORT_TYPE]?.filter((optionItem) => optionItem.isChecked) || []

  return {
    ...optionItemsToFilters[GetProductsFilterType.BRAND_ID](brandsOptions),
    ...optionItemsToFilters[GetProductsFilterType.CATEGORY](categoryOptions),
    ...optionItemsToFilters[GetProductsFilterType.COLOR](colorOptions),
    ...optionItemsToFilters[GetProductsFilterType.SIZE](sizeOptions),
    ...optionItemsToFilters[GetProductsFilterType.PRICE_RANGE](priceOptions),
    ...optionItemsToFilters[GetProductsFilterType.SUPPLIER](merchantOptions),
    ...optionItemsToFilters[GetProductsFilterType.SORT_TYPE](sortOptions),
  }
}
const useApplyFilters = (
  currentOptions: FilterOverlay.Options,
  setIsVisibleOverlay: Dispatch<SetStateAction<boolean>>,
  isVisibleOverlay: boolean,
  resetOptions: FilterOverlay.ResetOptionsFn,
): {
  applyFilters: (priceRange: {
    low: number
    high: number
    boundaryLow: number
    boundaryHigh: number
  }) => Promise<void>
} => {
  const { filters, changeFilters } = useFiltersQuery()

  const applyFilters = async (priceRange: {
    low: number
    high: number
    boundaryLow: number
    boundaryHigh: number
  }): Promise<void> => {
    const newFilters = optionsToFilters(currentOptions, filters, priceRange)
    await changeFilters(newFilters)
    setIsVisibleOverlay(!isVisibleOverlay)
    resetOptions(newFilters)
  }

  return { applyFilters }
}

export default useApplyFilters
