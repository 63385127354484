import React, { useContext } from 'react'
import { FilterOverlayContext } from '@shopper/app/components/Feed/FilterBar/Overlay/context'
import type { FilterOverlay } from '@shopper/app/components/Feed/FilterBar/Overlay/context/types'
import { Button } from '@shopper/ui/src'
import { CaptionStandardText } from '@shopper/ui/src/components/text/new'

interface SizeOptionItemProps {
  optionItem: FilterOverlay.OptionItem
}

export const SizeOptionItem: React.FC<SizeOptionItemProps> = ({ optionItem }) => {
  const { changeOptionItemIsChecked } = useContext(FilterOverlayContext)
  const { isChecked, name } = optionItem

  const backgroundColor = isChecked ? '$neutral300' : '$pureWhite'
  const borderColor = isChecked ? '$neutral700' : '$neutral300'
  const textColor = isChecked ? '$neutral1000' : '$neutral700'

  return (
    <Button
      onPress={(): void => changeOptionItemIsChecked(optionItem)}
      borderRadius={8}
      backgroundColor={backgroundColor}
      height={40}
      alignItems="center"
      justifyContent="center"
      paddingVertical={7.5}
      paddingHorizontal={10}
      borderWidth={1}
      borderColor={borderColor}
      hoverStyle={{
        backgroundColor,
        borderColor,
      }}
      pressStyle={{
        backgroundColor,
        borderColor,
      }}
      focusStyle={{
        backgroundColor,
        borderColor,
      }}
      focusVisibleStyle={{
        outlineStyle: 'none',
        backgroundColor,
        borderColor,
      }}
      minWidth={40}
    >
      <CaptionStandardText
        textAlign="center"
        color={textColor}
        ellipsizeMode="tail"
        fontWeight={isChecked ? '700' : '400'}
      >
        {name.replace(/_/g, ' ')}
      </CaptionStandardText>
    </Button>
  )
}
