import { useEffect, useState } from 'react'
import { isClient } from '@shopper/ui/src'
import { Dimensions } from 'react-native'

const getWindowDimensions = (): {
  width: number
  height: number
} => {
  if (isClient) {
    const { innerWidth: _width, innerHeight: height } = window
    const width = _width > 500 ? 500 : _width
    return {
      width,
      height,
    }
  } else {
    const { width: _width, height } = Dimensions.get('window')
    const width = _width > 500 ? 500 : _width
    return {
      width,
      height,
    }
  }
}

const useWindowDimensions = (): {
  width: number
  height: number
} => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize(): void {
      setWindowDimensions(getWindowDimensions())
    }

    if (isClient) window.addEventListener('resize', handleResize)
    return () => {
      if (isClient) window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowDimensions
}

export default useWindowDimensions
