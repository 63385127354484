import React, { useEffect, useRef } from 'react'
import FilterBar from '@shopper/app/components/Feed/FilterBar'
import { Footer } from '@shopper/app/components/Feed/ProductGroup/Footer'
import { ProductDisplay } from '@shopper/app/components/ProductDisplay/ProductDisplay'
import posthogClient from '@shopper/app/utils/services/analytics/posthog'
import type { FeedType } from '@shopper/app/utils/services/analytics/posthog/utils/FeedType'
import { YStack } from '@shopper/ui/src'
import type { ProductCompositePublic } from '@centrito/api/nest/shopper/catalog/products/domain/composites'
import InfiniteScroll from './InfiniteScroll'

const InfiniteScrollMemo = React.memo(InfiniteScroll)
const FilterBarMemo = React.memo(FilterBar)

interface ImpressionTrackingProps {
  productId: string
  productName: string
  position: number
  feedType: FeedType
}

const useImpressionTracking = ({
  productId,
  productName,
  position,
  feedType,
}: ImpressionTrackingProps): React.RefObject<HTMLDivElement> => {
  const productRef = useRef<HTMLDivElement>(null)
  const hasBeenViewed = useRef(false)
  const timeoutRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    const currentProduct = productRef.current
    if (!currentProduct) return

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0]

        if (entry.isIntersecting && !hasBeenViewed.current) {
          // Start the timer when product comes into view
          timeoutRef.current = setTimeout(() => {
            posthogClient.captureProductImpression(feedType, productId, productName, position)
            hasBeenViewed.current = true
          }, 1000)
        } else if (!entry.isIntersecting) {
          // Clear the timer if product goes out of view before 1s
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
          }
        }
      },
      {
        threshold: 1, // 100% of the item must be visible
      },
    )

    observer.observe(currentProduct)

    return (): void => {
      if (currentProduct) {
        observer.unobserve(currentProduct)
      }
      // Clean up timeout if component unmounts
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [productId, productName, position, feedType])

  return productRef
}

interface ProductWithImpressionProps {
  product: ProductCompositePublic
  index: number
  feedType: FeedType
}

const ProductWithImpressionComponent: React.FC<ProductWithImpressionProps> = ({
  product,
  index,
  feedType,
}) => {
  const ref = useImpressionTracking({
    productId: product.product.id,
    productName: product.product.name,
    position: index,
    feedType,
  })

  return (
    <YStack ref={ref}>
      <ProductDisplay
        key={'product-display-feed-' + product.product.id}
        product={product}
        feedType={feedType}
        index={index}
      />
    </YStack>
  )
}

const ProductWithImpression = React.memo(ProductWithImpressionComponent)

export interface FeedProductsGroupProps {
  products: ProductCompositePublic[]
  loadMoreProducts: () => void
  hasMoreProducts: boolean
  feedType: FeedType
  isShowingFilters?: boolean
  productsCount?: number
  header?: JSX.Element
}

const FeedProductsGroup: React.FC<FeedProductsGroupProps> = ({
  products,
  loadMoreProducts,
  hasMoreProducts,
  feedType,
  isShowingFilters = true,
  productsCount = undefined,
  header = undefined,
}) => {
  const data = [
    ...products.map((product, index) => (
      <ProductWithImpression
        key={'product-with-impression-' + index}
        product={product}
        index={index}
        feedType={feedType}
      />
    )),
    <InfiniteScrollMemo
      key={products.length}
      loadMore={loadMoreProducts}
      hasMore={hasMoreProducts}
    />,
  ]

  const generateChunks = (head: JSX.Element[], tail: JSX.Element[][]): JSX.Element[][] => {
    if (head.length > 0) {
      const newHead = head.slice(2)
      const newTail = [...tail, head.slice(0, 2)]

      return generateChunks(newHead, newTail)
    }
    return tail
  }

  const chunks = generateChunks(data, [])

  return (
    <YStack>
      {isShowingFilters && (
        <YStack>
          {header}
          <FilterBarMemo productsCount={productsCount} />
        </YStack>
      )}
      <YStack
        $platform-web={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 6,
          padding: 3,
        }}
      >
        {chunks}
      </YStack>
      {<Footer hasMoreProducts={hasMoreProducts} numProducts={products.length} />}
    </YStack>
  )
}

export default FeedProductsGroup
