import React, { useContext } from 'react'
import { FilterOverlayContext } from '@shopper/app/components/Feed/FilterBar/Overlay/context'
import type { FilterOverlay } from '@shopper/app/components/Feed/FilterBar/Overlay/context/types'
import { ListItemRadioButton } from '@shopper/ui/src/components/ListItemRadioButton'
import { ContentStandardText } from '@shopper/ui/src/components/text/new'

interface SortOptionItemProps {
  optionItem: FilterOverlay.OptionItem
}

export const SortOptionItem: React.FC<SortOptionItemProps> = ({ optionItem }) => {
  const { name, isChecked, _filterItem } = optionItem
  const { changeOptionItemIsChecked } = useContext(FilterOverlayContext)

  return (
    <ListItemRadioButton
      id={_filterItem.value?.toString() || ''}
      value={_filterItem.value?.toString() || ''}
      onPress={() => changeOptionItemIsChecked(optionItem)}
      borderColor={isChecked ? '$primary1000' : '$neutral300'}
      paddingHorizontal={15}
    >
      <ContentStandardText color="$neutral700">{name}</ContentStandardText>
    </ListItemRadioButton>
  )
}
