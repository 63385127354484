import React, { useContext } from 'react'
import { FilterOverlayContext } from '@shopper/app/components/Feed/FilterBar/Overlay/context'
import useActiveFeedFilterPills from '@shopper/app/utils/hooks/useActiveFeedFilterPills'
import { useFiltersQuery } from '@shopper/app/utils/hooks/useFeedProducts/useFiltersQuery'
import { XStack } from '@shopper/ui/src'
import isNil from 'lodash.isnil'
import { createParam } from 'solito'
import { GetProductsFilterType, ProductSortType } from '@centrito/api/shared/enums'
import Pill from './Item'

type FilterParamsCatalog = {
  brandId?: string
  merchantId?: string
  sortType?: ProductSortType
}

const { useParams } = createParam<FilterParamsCatalog>()

const SORT_LABELS: Record<ProductSortType, string | undefined> = {
  [ProductSortType.MOST_RECENT]: 'Más recientes',
  [ProductSortType.HIGHEST_PRICE]: 'Mayor precio',
  [ProductSortType.LOWEST_PRICE]: 'Menor precio',
  [ProductSortType.NONE]: undefined,
}

const FeedFilterBarPillGroup: React.FC = () => {
  const { activeFeedFilterPills } = useActiveFeedFilterPills()
  const { removeFilter } = useFiltersQuery()
  const { resetOptions } = useContext(FilterOverlayContext)
  const { params } = useParams()

  const excludedIds = [params.brandId, params.merchantId].filter((id) => !isNil(id))

  return (
    activeFeedFilterPills.length > 0 && (
      <XStack
        flexWrap="wrap"
        paddingHorizontal={14}
        paddingVertical={8}
        gap={8}
        width="100%"
        borderTopColor="#e9e9ea"
        borderTopWidth={1}
      >
        {activeFeedFilterPills
          .filter((pill) => !excludedIds.includes(pill.id ?? ''))
          .map((pill) => {
            const removePill = async (): Promise<void> => {
              const newFilters = await removeFilter(pill.filter)
              resetOptions(newFilters)
            }

            const pillName =
              pill.filter.type === GetProductsFilterType.SORT_TYPE
                ? SORT_LABELS[pill.filter.value as ProductSortType]
                : pill.name

            return (
              <Pill
                key={pillName}
                name={pillName ?? ''}
                onClickRemove={removePill}
                filterType={pill.filter.type}
              />
            )
          })}
      </XStack>
    )
  )
}

export default FeedFilterBarPillGroup
