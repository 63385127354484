import React from 'react'
import { XStack } from '@shopper/ui/src'
import LittleLoadingFooter from './LittleLoadingFooter'
import NoMoreProductsFooter from './NoMoreProductsFooter'

export const Footer: React.FC<{ hasMoreProducts: boolean; numProducts: number }> = ({
  hasMoreProducts,
  numProducts,
}) => {
  return (
    <XStack width="100%" justifyContent="center" alignItems="center" paddingBottom={75}>
      {hasMoreProducts ? <LittleLoadingFooter /> : numProducts > 4 && <NoMoreProductsFooter />}
    </XStack>
  )
}
