import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { PromotionalCampaignDiscountPresentation } from '@prisma/client/react-native'
import { AppStateContext } from '@shopper/app/provider/shopper/appState'
import { useDispatch } from '@shopper/app/store'
import { actions } from '@shopper/app/store/slices/userData'
import { getSearchVariant } from '@shopper/app/utils/helpers'
import { useFiltersQuery } from '@shopper/app/utils/hooks/useFeedProducts/useFiltersQuery'
import useWindowDimensions from '@shopper/app/utils/hooks/useWindowDimensions'
import { getPercentageDiscount } from '@shopper/app/utils/products'
import posthogClient from '@shopper/app/utils/services/analytics/posthog'
import type { FeedType } from '@shopper/app/utils/services/analytics/posthog/utils/FeedType'
import { YStack } from '@shopper/ui/src'
import { DISCOUNT_TAGS } from '@shopper/ui/src/components/outlet'
import isNil from 'lodash.isnil'
import { Pressable } from 'react-native'
import { createParam } from 'solito'
import { useRouter } from 'solito/router'
import PATHS from '@centrito/common/paths'
import type { ProductCompositePublic } from '@centrito/api/nest/shopper/catalog/products/domain/composites'
import { GetProductsFilterType } from '@centrito/api/shared/enums'
import {
  CompositePrice,
  FlashSale,
  PercentageDiscount,
  ProductDisplayVariants,
  ProductImage,
} from './components'

export interface FeedProductDisplayProps {
  product: ProductCompositePublic
  feedType?: FeedType
  index?: number
  isCartButton?: boolean
  isInFlashList?: boolean
}

type SearchBarQueryParams = {
  searchTerm: string
}
const { useParam } = createParam<SearchBarQueryParams>()

export const ProductDisplay: React.FC<FeedProductDisplayProps> = ({
  product,
  feedType,
  index,
  isCartButton = false,
  isInFlashList = false,
}) => {
  const { filters } = useFiltersQuery()
  const [priceRange, setPriceRange] = useState<{
    low: number | undefined
    high: number | undefined
  }>({
    low: undefined,
    high: undefined,
  })
  const { setSelectedProduct } = useContext(AppStateContext)
  const percentageDiscount = !isNil(product) ? getPercentageDiscount(product) : 0
  const isFlashSale = 0

  const filteredColors = filters[GetProductsFilterType.COLOR]?.join(' ')
  const [searchTermUrlPath] = useParam('searchTerm')
  const searchTermProduct = searchTermUrlPath ?? filteredColors ?? null

  const [cardWidth, setCardWidth] = useState<number>(0)
  const [variantIndex, setVariantIndex] = useState<number>(
    searchTermProduct ? getSearchVariant(searchTermProduct, product.variants) : 0,
  )
  const [variantImage, setVariantImage] = useState<string>(
    product.variants[variantIndex]?.images[0]?.urlWebP ||
      product.variants[variantIndex]?.images[0]?.url ||
      '',
  )
  const imagePlaceholder = product.variants[variantIndex]?.images[0]?.urlWebP ?? ''

  const toProductLink = useMemo(
    () => ({
      pathname: PATHS.Products.Product,
      query: { productId: product.product.id, variantIndex: variantIndex },
    }),
    [product.product.id, variantIndex],
  )
  const router = useRouter()
  const dispatch = useDispatch()

  const handleImagePress = useCallback((): void => {
    setSelectedProduct(product)
    if (!isNil(feedType) && !isNil(index)) {
      posthogClient.captureProductClick(feedType, product.product.id, product.product.name, index)
      dispatch(actions.setSessionProductIds({ productId: product.product.id }))
    }
    router.push(toProductLink)
  }, [setSelectedProduct, product, feedType, index, router, toProductLink, dispatch])

  useEffect(() => {
    setVariantImage(
      product.variants[variantIndex]?.images[0]?.urlWebP ||
        product.variants[variantIndex]?.images[0]?.url ||
        '',
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantIndex])

  useEffect(() => {
    const prices: number[] = product.variants
      .flatMap((variant) => variant.variant.data.sizes.map((size) => size.customPriceRetail))
      .filter((price): price is number => !isNil(price) && price > 0)
    if (prices.length === 0) {
      prices.push(product.pricingData.priceRetail)
    }
    setPriceRange({ low: Math.min(...prices), high: Math.max(...prices) })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])
  const { width: _width } = useWindowDimensions()
  const responsiveWidth = _width < 500 ? _width / 2 - 7 : _width / 2 - 25
  const responsiveHeight = responsiveWidth * 1.1
  const retailPrice = product.pricingData.priceRetail

  const determineDiscountTag = useCallback(() => {
    const MerchantDiscountTag = DISCOUNT_TAGS[product.merchant.name]?.Small

    if (!isNil(MerchantDiscountTag)) {
      return <MerchantDiscountTag discount={percentageDiscount} cardWidth={cardWidth} />
    }

    return (
      <PercentageDiscount
        productDiscount={percentageDiscount}
        category={product.category}
        brand={product.brand}
      />
    )
  }, [percentageDiscount, product, cardWidth])

  return (
    <YStack
      id={product.product.id}
      $platform-native={{
        flexBasis: isInFlashList ? undefined : '50%',
        height: isInFlashList ? (_width / 500 < 1 ? responsiveHeight + 50 : 305) : undefined,
        width: isInFlashList ? responsiveWidth : undefined,
        margin: isInFlashList ? 3 : 0,
      }}
      $platform-web={{
        flexGrow: 1,
      }}
      borderRadius={8}
      padding={0}
      backgroundColor="$pureWhite"
      onLayout={(e) => setCardWidth(e.nativeEvent.layout.width)}
    >
      <YStack aspectRatio={167 / 190} width="100%" marginBottom={8}>
        <Pressable
          onPress={handleImagePress}
          style={{
            width: '100%',
          }}
        >
          <ProductImage
            variantImage={variantImage}
            alt={product.product.name}
            placeholder={imagePlaceholder}
          />
        </Pressable>
        {product.variants.length > 1 && (
          <YStack
            position="absolute"
            justifyContent="flex-end"
            alignItems="flex-end"
            zIndex={2}
            bottom={5}
            right={5}
            gap={3}
            width={18}
          >
            <ProductDisplayVariants
              variants={product.variants}
              variantIndex={variantIndex}
              setVariantIndex={setVariantIndex}
              maxVariantDisplay={2}
              toProductLink={toProductLink}
            />
          </YStack>
        )}
        <YStack position="absolute" justifyContent="flex-end" zIndex={2} bottom={5} left={5}>
          {determineDiscountTag()}
        </YStack>
        <YStack
          position="absolute"
          justifyContent="flex-start"
          alignItems="flex-start"
          zIndex={2}
          top={4}
        >
          {isFlashSale != 0 && <FlashSale productDiscount={percentageDiscount} />}
        </YStack>
      </YStack>
      <CompositePrice
        productName={product.product.name}
        productDiscount={product.pricingData.priceDiscountItemPublic.amount}
        priceRange={priceRange}
        isListPrice={
          product.discountPublicCampaign?.type ===
            PromotionalCampaignDiscountPresentation.PUBLIC_MAGAZINE_DISCOUNT &&
          product.pricingData.priceDiscountItemPublic.amount != 0
        }
        isPublicDiscount={
          product.discountPublicCampaign?.type ===
            PromotionalCampaignDiscountPresentation.PUBLIC_STANDARD_DISCOUNT &&
          product.pricingData.priceDiscountItemPublic.amount != 0
        }
        isCartButton={isCartButton}
        toProductLink={toProductLink}
        retailPrice={retailPrice}
      />
    </YStack>
  )
}
