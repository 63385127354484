import React from 'react'
import { SamsamWinkingLogoWithText } from '@shopper/app/components/BrandNameCircleLogo'
import { XStack, YStack } from '@shopper/ui/src'
import { LOGO_SIZES } from '@shopper/ui/src/components/outlet'
import { OUTLETS_LOGOS } from '@shopper/ui/src/components/svgs/outlets/logos'
import { DefaultTextPrimary } from '@shopper/ui/src/components/text'
import { ShoppingCart } from '@tamagui/lucide-icons'
import isNil from 'lodash.isnil'
import isUndefined from 'lodash.isundefined'

const getMerchant = (merchantName?: string) => {
  if (isNil(merchantName)) {
    return null
  }

  const outletLogo = OUTLETS_LOGOS[merchantName as keyof typeof OUTLETS_LOGOS]

  if (!isNil(outletLogo)) {
    return outletLogo(LOGO_SIZES[merchantName as keyof typeof LOGO_SIZES]?.big)
  }

  return <SamsamWinkingLogoWithText size="big" text="Vendedor Samsam" />
}

export interface MerchantCardProps {
  name: string
  orderCount?: number
}

const MerchantCard: React.FC<MerchantCardProps> = ({ name, orderCount = undefined }) => {
  return (
    <XStack alignItems="center" paddingHorizontal={20}>
      <YStack>
        {getMerchant(name)}
        {!isUndefined(orderCount) && orderCount > 50 && (
          <XStack alignItems="center" paddingVertical={5}>
            <ShoppingCart size={20} color="#7f7f7f" />
            <DefaultTextPrimary marginLeft={5} fontSize={15}>
              {orderCount}
            </DefaultTextPrimary>
            <DefaultTextPrimary marginLeft={4} color="#7f7f7f" fontSize={15}>
              ventas
            </DefaultTextPrimary>
          </XStack>
        )}
      </YStack>
    </XStack>
  )
}

export default MerchantCard
