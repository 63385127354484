import React, { useContext } from 'react'
import { FilterOverlayContext } from '@shopper/app/components/Feed/FilterBar/Overlay/context'
import type { FilterOverlay } from '@shopper/app/components/Feed/FilterBar/Overlay/context/types'
import { Checkbox, XStack } from '@shopper/ui/src'
import { DefaultTextPrimary } from '@shopper/ui/src/components/text'
import { Check as CheckIcon } from '@tamagui/lucide-icons'

interface MerchantOptionItemProps {
  optionItem: FilterOverlay.OptionItem
}

export const MerchantOptionItem: React.FC<MerchantOptionItemProps> = ({ optionItem }) => {
  const { changeOptionItemIsChecked } = useContext(FilterOverlayContext)
  const { name, icon, isChecked } = optionItem

  return (
    <XStack
      onPress={(): void => changeOptionItemIsChecked(optionItem)}
      width="100%"
      alignItems="center"
      gap={10}
      paddingVertical={4.5}
    >
      {icon}
      <DefaultTextPrimary fontSize={14} numberOfLines={2} color="$darkGray">
        {name}
      </DefaultTextPrimary>

      <Checkbox
        marginLeft="auto"
        checked={isChecked}
        onCheckedChange={(): void => changeOptionItemIsChecked(optionItem)}
        width={22}
        height={22}
        padding={0}
        overflow="hidden"
        borderRadius={5}
      >
        <Checkbox.Indicator backgroundColor="$primary1000" width="100%" height="100%">
          <CheckIcon color="$pureWhite" margin="auto" size={16} />
        </Checkbox.Indicator>
      </Checkbox>
    </XStack>
  )
}
