import React from 'react'
import { XStack, YStack } from '@shopper/ui/src'
import { DetailText3 } from '@shopper/ui/src/components/text/new/detail/DetailText3'
import { Link } from 'solito/link'
import type { ProductVariantDbPublicComposite } from '@centrito/api/nest/platform/database/domain'
import { VariantsColorBubble } from './VariantsBubble'

interface AvailableColorsProps {
  variants: ProductVariantDbPublicComposite[]
  variantIndex: number
  setVariantIndex: React.Dispatch<React.SetStateAction<number>>
  maxVariantDisplay: number
  toProductLink: {
    pathname: string
    query: { [key: string]: string | number }
  }
}

export const ProductDisplayVariants: React.FC<AvailableColorsProps> = ({
  variants,
  setVariantIndex,
  maxVariantDisplay,
  toProductLink,
}) => {
  const colorBubbles = variants
    .slice(0, maxVariantDisplay)
    .reduce(
      (acumElements: JSX.Element[], variant: ProductVariantDbPublicComposite, index: number) => {
        if (variants.length > 1 && variant.images.length !== 0) {
          const variantColors = variant.variant.colorData

          acumElements.push(
            <VariantsColorBubble
              key={variant.variant.id}
              variantColors={variantColors}
              currentIndex={index}
              setVariantIndex={setVariantIndex}
            />,
          )
        }
        return acumElements
      },
      [],
    )
  return (
    <YStack
      alignItems="center"
      columnGap="$1"
      justifyContent="center"
      gap={3}
      width={18}
      backgroundColor={variants.length > 1 ? 'rgba(255, 255, 255, 0.7)' : 'transparent'}
      borderRadius={4}
      paddingTop={5}
      paddingBottom={variants.length <= 2 ? 4 : 0}
    >
      {colorBubbles}
      {variants.length > maxVariantDisplay && (
        <XStack>
          <Link href={toProductLink}>
            <DetailText3 color="$mediumGray" fontSize={11}>
              {' '}
              {variants.length - 2}{' '}
            </DetailText3>
          </Link>
        </XStack>
      )}
    </YStack>
  )
}
