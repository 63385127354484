import React from 'react'

interface VerTodoSvgProps {
  color?: string
}

const VerTodoSvg: React.FC<VerTodoSvgProps> = ({ color = '#55808A' }) => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_18409_210675)">
        <path
          d="M14.4704 9.5L10.4704 9.5C9.91808 9.5 9.47037 9.94772 9.47037 10.5L9.47037 14.5C9.47037 15.0523 9.91808 15.5 10.4704 15.5L14.4704 15.5C15.0227 15.5 15.4704 15.0523 15.4704 14.5V10.5C15.4704 9.94772 15.0227 9.5 14.4704 9.5Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4704 18.5L10.4704 18.5C9.91808 18.5 9.47037 18.9477 9.47037 19.5L9.47037 23.5C9.47037 24.0523 9.91808 24.5 10.4704 24.5L14.4704 24.5C15.0227 24.5 15.4704 24.0523 15.4704 23.5V19.5C15.4704 18.9477 15.0227 18.5 14.4704 18.5Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.4704 9.5L19.4704 9.5C18.9181 9.5 18.4704 9.94772 18.4704 10.5L18.4704 14.5C18.4704 15.0523 18.9181 15.5 19.4704 15.5L23.4704 15.5C24.0227 15.5 24.4704 15.0523 24.4704 14.5V10.5C24.4704 9.94772 24.0227 9.5 23.4704 9.5Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.4704 18.5L19.4704 18.5C18.9181 18.5 18.4704 18.9477 18.4704 19.5L18.4704 23.5C18.4704 24.0523 18.9181 24.5 19.4704 24.5L23.4704 24.5C24.0227 24.5 24.4704 24.0523 24.4704 23.5V19.5C24.4704 18.9477 24.0227 18.5 23.4704 18.5Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18409_210675">
          <rect width="24" height="24" fill="white" transform="translate(0 17) rotate(-45)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default VerTodoSvg
