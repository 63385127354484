import React from 'react'
import { DetailText1 } from '@shopper/ui/src/components/text/new'
import { ContentBoldText, ContentStandardText } from '@shopper/ui/src/components/text/new/content'
import { XStack } from 'tamagui'

export interface DiscountTagProps {
  logo: React.ReactNode
  logoBackgroundColor?: string
  discount: number
  size?: 'small' | 'big'
  cardWidth?: number
}

export const DiscountTag: React.FC<DiscountTagProps> = ({
  logo,
  logoBackgroundColor = '$pureWhite',
  discount,
  size = 'small',
  cardWidth = 0,
}) => {
  if (discount > 0 && size === 'small' && cardWidth <= 150) {
    return (
      <XStack
        backgroundColor="#ffd148"
        borderTopLeftRadius={8}
        padding={3}
        marginLeft={6}
        marginTop={7}
        borderBottomRightRadius={8}
        justifyContent="center"
        alignItems="center"
      >
        <DetailText1 color="$deepShopperBlack" fontWeight="700">
          -{discount}%
        </DetailText1>
      </XStack>
    )
  }

  return (
    <XStack
      backgroundColor="#ffd148"
      overflow="hidden"
      borderRadius={5}
      flexGrow={0}
      flexShrink={1}
      $platform-web={{
        width: 'fit-content',
      }}
      alignSelf="flex-start"
    >
      <XStack
        backgroundColor={logoBackgroundColor}
        borderWidth={1}
        borderColor="$neutral400"
        paddingHorizontal={6}
        borderRadius={5}
        paddingVertical={3.5}
        alignItems="center"
        margin="auto"
        flexShrink={0}
        height="100%"
      >
        {logo}
      </XStack>
      {discount > 0 && (
        <XStack paddingHorizontal={5} paddingVertical={5} marginVertical="auto">
          {size === 'big' ? (
            <ContentStandardText color="$deepShopperBlue">
              {' '}
              OUTLET <ContentBoldText color="$deepShopperBlue">-{discount}%</ContentBoldText>
            </ContentStandardText>
          ) : (
            <DetailText1 color="$deepShopperBlack">-{discount}%</DetailText1>
          )}
        </XStack>
      )}
    </XStack>
  )
}
