import React from 'react'
import { Input, type InputProps, XStack, YStack } from '@shopper/ui/src'
import MagnifyingGlass from '@shopper/ui/src/components/svgs/assets/MagnifyingGlass'
import { X } from '@tamagui/lucide-icons'
import isEmpty from 'lodash.isempty'
import type {
  NativeSyntheticEvent,
  TextInput,
  TextInputChangeEventData,
  TextInputKeyPressEventData,
} from 'react-native'

export interface SearchBrandProps extends InputProps {
  name: string | undefined
  textValue?: string
  isDisabled?: boolean
  setTextValue?: ((value: React.SetStateAction<string>) => void) | ((value: string) => void)
  onChange?: (e: NativeSyntheticEvent<TextInputChangeEventData>) => void
  onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void
  onFocus?: () => void
  onSubmitEditing?: () => void
  placeholder?: string
  borderRadius?: number
  inputFontSize?: number
  height?: number
}
export const SearchBrand: React.FC<SearchBrandProps> = ({
  name,
  textValue = '',
  isDisabled,
  setTextValue = (): null => null,
  onChange = (): null => null,
  onKeyPress = (): null => null,
  onBlur = (): null => null,
  onFocus = (): null => null,
  onSubmitEditing = (): null => null,
  placeholder = '',
  borderRadius = 11,
  inputFontSize = 12,
  height = 35,
}) => {
  const [isFocused, setIsFocused] = React.useState(false)
  const inputRef = React.useRef<TextInput>(null)
  const handleIconClick = (): void => {
    inputRef.current?.focus()
  }

  const handleChange = (e: NativeSyntheticEvent<TextInputChangeEventData>): void => {
    onChange(e)
  }

  return (
    <XStack
      paddingHorizontal={10}
      borderWidth={1}
      borderColor={isFocused ? '$standardGray' : '$lightSkyGray'}
      backgroundColor="$lightSkyGray"
      borderRadius={borderRadius}
      height={height}
      justifyContent={'space-between'}
      alignItems="center"
      width="100%"
    >
      <YStack flex={1}>
        <XStack>
          <XStack alignItems="center" onPress={handleIconClick} paddingRight={10} zIndex={999}>
            <MagnifyingGlass />
          </XStack>
          <Input
            ref={inputRef}
            id={name}
            flex={1}
            height={height - 3}
            fontFamily="$poppinsFont"
            placeholder={placeholder}
            placeholderTextColor="$lighterGray"
            textContentType="none"
            letterSpacing={0}
            keyboardType="default"
            backgroundColor={'$lightSkyGray'}
            borderRadius={borderRadius}
            width="100%"
            onFocus={(): void => {
              setIsFocused(true)
              onFocus()
            }}
            onBlur={(e): void => {
              setIsFocused(false)
              onBlur(e)
            }}
            onSubmitEditing={(): void => {
              setIsFocused(false)
              onSubmitEditing()
            }}
            onChange={handleChange}
            onKeyPress={onKeyPress}
            onChangeText={(text): void => setTextValue(text)}
            disabled={isDisabled}
            maxLength={50}
            borderWidth={0}
            focusStyle={{
              borderWidth: 0,
              outlineStyle: 'none',
            }}
            paddingLeft={0}
            fontSize={inputFontSize}
            fontWeight="400"
            color="$pureBlack"
            value={textValue}
            style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
          />
        </XStack>
      </YStack>
      {isEmpty(textValue) && (
        <XStack zIndex={10} onPress={(): void => setTextValue('')}>
          <X width={24} height={24} />
        </XStack>
      )}
    </XStack>
  )
}
