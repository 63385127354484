import React from 'react'
import { YStack } from '@shopper/ui/src'
import { SubtitleText1 } from '@shopper/ui/src/components/text/new'

export interface ListPriceProps {
  fullPrice: number
  fontSize: number
  campaign: boolean
}

export const ListPrice: React.FC<ListPriceProps> = ({ fullPrice, fontSize, campaign }) => {
  return campaign ? (
    <YStack justifyContent="space-around" columnGap={8}>
      <SubtitleText1
        fontSize={fontSize}
        lineHeight={fontSize + 1}
        fontWeight="400"
        color="$brightOrange"
        textDecorationLine="line-through"
      >
        ${`${new Intl.NumberFormat('es-CO').format(fullPrice)}`}
      </SubtitleText1>
      <SubtitleText1
        fontSize={fontSize}
        lineHeight={fontSize + 1}
        fontWeight="400"
        color="$brightOrange"
      >
        en revista
      </SubtitleText1>
    </YStack>
  ) : (
    <React.Fragment />
  )
}
