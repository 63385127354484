import React from 'react'
import { ScrollView } from '@shopper/ui/src'
import { CircleFeedButton } from '@shopper/ui/src/components/button/CircleFeedButton'
import PATHS from '@centrito/common/paths'
import type { CategoryPublic } from '@centrito/api/nest/platform/database/domain'

interface ProductListCategoriesSliderProps {
  categories: CategoryPublic[]
  listId: string
}

export const ProductListCategoriesSlider: React.FC<ProductListCategoriesSliderProps> = ({
  categories,
  listId,
}) => {
  return (
    <ScrollView height={85} showsHorizontalScrollIndicator={false} horizontal>
      {categories.map((category) => (
        <CircleFeedButton
          key={`category-browser--${category.nodes}`}
          href={{
            pathname: PATHS.Products.List,
            query: {
              listId: listId,
              selectedCategory: category.nodes,
            },
          }}
          imageSrc={category.assets.tab || ''}
          imageAlt={category.nodes}
          label={category.nodesNamed.split('-').pop() || ''}
          containerHeight={60}
        />
      ))}
    </ScrollView>
  )
}
