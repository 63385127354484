import React from 'react'
import isNil from 'lodash.isnil'
import { Label, RadioGroup, XStack, isClient } from 'tamagui'

export interface ListItemRadioButtonProps {
  id: string
  value: string
  children: React.ReactNode
  onPress?: () => void
  isDisabled?: boolean
  borderColor?: string
  paddingHorizontal?: number
}

export const ListItemRadioButton: React.FC<ListItemRadioButtonProps> = ({
  id,
  value,
  children,
  onPress,
  isDisabled = false,
  borderColor = '$primary1000',
  paddingHorizontal = 0,
}) => {
  const content = (
    <XStack
      gap="$3"
      justifyContent="space-between"
      alignItems="center"
      width="$full"
      flex={1}
      paddingHorizontal={paddingHorizontal}
    >
      {children}
      <RadioGroup.Item
        value={value}
        id={`radiogroup-item-${id}`}
        width={28}
        height={28}
        borderColor={borderColor}
        borderWidth={2}
        disabled={isDisabled}
        backgroundColor={isDisabled ? '$brightGray' : undefined}
        justifyContent="center"
        alignItems="center"
        focusStyle={{ borderColor: borderColor }}
        hoverStyle={{ borderColor: borderColor }}
        pressStyle={{ borderColor: borderColor }}
        onPress={!isNil(onPress) ? onPress : (): void => {}}
      >
        <RadioGroup.Indicator backgroundColor="$primary1000" width={12} height={12} />
      </RadioGroup.Item>
    </XStack>
  )

  return isClient ? (
    <Label
      unstyled
      htmlFor={`radiogroup-item-${id}`}
      borderRadius="$3"
      alignItems="center"
      width={'$full'}
    >
      {content}
    </Label>
  ) : (
    content
  )
}
