import React from 'react'
import { Square } from '@shopper/ui/src'
import { CircleUser, Coins, Ruler } from '@tamagui/lucide-icons'
import { SolitoImage as Image } from 'solito/image'
import type { Brand } from '@centrito/db/prisma/generated/zod'
import type { CategoryPublic } from '@centrito/api/nest/platform/database/domain'
import type { GetProducts } from '@centrito/api/shared/catalog'
import { GetProductsFilterType, ProductSortType } from '@centrito/api/shared/enums'
import getIsChecked from './getIsChecked'
import type { FilterOverlay } from './types'

const getInitialOptions = (
  filters: GetProducts.Filters,
  categories: CategoryPublic[] | undefined,
  brands: Brand[] | undefined,
  merchants: { merchantName: string; merchantId: string }[] | undefined,
  colors: { hex: string; key: string; name: string }[] | undefined,
  sizes: string[],
  priceRange: { low: number; high: number },
): FilterOverlay.Options => {
  return {
    [GetProductsFilterType.BRAND_ID]:
      brands?.map((brand) => {
        const filterItem = {
          type: GetProductsFilterType.BRAND_ID,
          value: brand.id,
        }
        return {
          name: brand.name,
          icon: <CircleUser color="$samsamBlue" />,
          isChecked: getIsChecked(filters, filterItem),
          _filterItem: filterItem,
        }
      }) ?? [],
    [GetProductsFilterType.CATEGORY]:
      categories?.map((category) => {
        const filterItem = {
          type: GetProductsFilterType.CATEGORY,
          value: category.nodes,
        }
        return {
          name: category.nodesNamed,
          icon: (
            <Image
              src={category.assets.burger ?? ''}
              alt={category.nodesNamed}
              width={34}
              height={34}
            />
          ),
          isChecked: getIsChecked(filters, filterItem),
          _filterItem: filterItem,
        }
      }) ?? [],
    [GetProductsFilterType.COLOR]:
      colors?.map((color) => {
        const filterItem = {
          type: GetProductsFilterType.COLOR,
          value: color.name,
        }
        return {
          name: color.name,
          icon: (
            <Square
              backgroundColor={color.hex}
              size={40}
              borderRadius={8}
              borderColor="#7F7F7F"
              borderWidth={1}
            />
          ),
          isChecked: getIsChecked(filters, filterItem),
          _filterItem: filterItem,
        }
      }) ?? [],
    [GetProductsFilterType.SIZE]:
      sizes?.map((presentation) => {
        const filterItem = {
          type: GetProductsFilterType.SIZE,
          value: presentation,
        }
        return {
          name: presentation,
          icon: <Ruler />,
          isChecked: getIsChecked(filters, filterItem),
          _filterItem: filterItem,
        }
      }) ?? [],
    [GetProductsFilterType.PRICE_RANGE]: [
      {
        name: 'Price range',
        icon: <Coins color="$samsamBlue" />,
        isChecked: true,
        _filterItem: { type: GetProductsFilterType.PRICE_RANGE, value: priceRange },
      },
    ],
    [GetProductsFilterType.SUPPLIER]:
      merchants?.map((merchant) => {
        const filterItem = {
          type: GetProductsFilterType.SUPPLIER,
          value: merchant.merchantId,
        }
        return {
          name: merchant.merchantName,
          icon: <CircleUser color="$samsamBlue" />,
          isChecked: getIsChecked(filters, filterItem),
          _filterItem: filterItem,
        }
      }) ?? [],
    [GetProductsFilterType.SORT_TYPE]: [
      {
        name: 'Más recientes',
        icon: undefined,
        isChecked: filters[GetProductsFilterType.SORT_TYPE] === ProductSortType.MOST_RECENT,
        _filterItem: {
          type: GetProductsFilterType.SORT_TYPE,
          value: ProductSortType.MOST_RECENT,
        },
      },
      {
        name: 'Mayor precio',
        icon: undefined,
        isChecked: filters[GetProductsFilterType.SORT_TYPE] === ProductSortType.HIGHEST_PRICE,
        _filterItem: {
          type: GetProductsFilterType.SORT_TYPE,
          value: ProductSortType.HIGHEST_PRICE,
        },
      },
      {
        name: 'Menor precio',
        icon: undefined,
        isChecked: filters[GetProductsFilterType.SORT_TYPE] === ProductSortType.LOWEST_PRICE,
        _filterItem: {
          type: GetProductsFilterType.SORT_TYPE,
          value: ProductSortType.LOWEST_PRICE,
        },
      },
    ],
  }
}

export default getInitialOptions
