import React from 'react'
import EmptyCart from '@shopper/ui/src/components/svgs/assets/EmptyCart'
import { DefaultTextPrimary } from '@shopper/ui/src/components/text'
import { Spacer, YStack } from 'tamagui'

export const NoMoreProductsFooter: React.FC = () => (
  <YStack alignItems="center">
    <EmptyCart />
    <Spacer size="$2" />
    <DefaultTextPrimary color="#989898">¡No hay más productos!</DefaultTextPrimary>
  </YStack>
)

export default NoMoreProductsFooter
