import Sentry from '@shopper/app/sentry/shopper'
import isNil from 'lodash.isnil'
import type { FatalError } from '@centrito/api/nest/platform/sentry/domain/errors'

export default function (this: void, error: FatalError, extra?: Record<string, unknown>): void {
  if (!error) return

  const { area, engineer } = error || {}

  // Set tags for area and engineer if provided
  const tags: Record<string, string> = {}
  if (!isNil(area)) tags.area = area
  if (!isNil(engineer)) tags.engineer = engineer

  // Capture the exception with fatal level and tags
  Sentry.captureException(error, {
    level: 'fatal',
    tags,
    extra,
  })
}
