import type { Dispatch, SetStateAction } from 'react'
import type React from 'react'
import { PRICE_RANGE_DEFAULT } from '@shopper/app/components/Feed/FilterBar/Overlay/context/constants'
import type { CategoryPublic } from '@centrito/api/nest/platform/database/domain'
import type { GetProducts } from '@centrito/api/shared/catalog'
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { GetProductsFilterType, ProductSortType } from '@centrito/api/shared/enums'
import { INITIAL_OPTIONS } from './constants'

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace FilterOverlay {
  export type OverlayFilterType = Extract<
    GetProductsFilterType,
    | GetProductsFilterType.BRAND_ID
    | GetProductsFilterType.CATEGORY
    | GetProductsFilterType.COLOR
    | GetProductsFilterType.SIZE
    | GetProductsFilterType.PRICE_RANGE
    | GetProductsFilterType.SUPPLIER
    | GetProductsFilterType.SORT_TYPE
  >
  export enum OverlayFilterEnum {
    BRAND_ID = GetProductsFilterType.BRAND_ID,
    CATEGORY = GetProductsFilterType.CATEGORY,
    COLOR = GetProductsFilterType.COLOR,
    SIZE = GetProductsFilterType.SIZE,
    PRICE_RANGE = GetProductsFilterType.PRICE_RANGE,
    SUPPLIER = GetProductsFilterType.SUPPLIER,
    SORT_TYPE = GetProductsFilterType.SORT_TYPE,
  }
  export type OptionItem<K extends OverlayFilterType = OverlayFilterType> = {
    name: string
    icon?: JSX.Element
    isChecked: boolean
    _filterItem: GetProducts.FilterItem<K>
  }
  export type Options = {
    [K in OverlayFilterType]: OptionItem<K>[] | undefined
  }
  export type ChangeOptionItemIsCheckedFn = (targetOptionItem: OptionItem) => void
  export type ApplyFiltersFn = (priceRange?: {
    low: number
    high: number
    boundaryLow: number
    boundaryHigh: number
    sortType: ProductSortType
  }) => void
  export type ResetOptionsFn = (newFilters: GetProducts.Filters) => void
  export type ClearOptionsFn = () => void

  export type Context = {
    categories: CategoryPublic[]
    options: Options
    isVisibleOverlay: boolean
    currentNode: string | undefined
    isBrandFilter: boolean
    brandId: string | undefined
    currentNodeDepth: number
    currentFilterDrawer: OverlayFilterType | undefined
    categoryPrefix: string | undefined
    priceRange: number[]
    priceRangeBoundaries: {
      low: number
      high: number
    }
    sortType: ProductSortType
    setPriceRange: React.Dispatch<React.SetStateAction<number[]>>
    isFetchingCategories: boolean
    setCurrentFilterDrawer: Dispatch<SetStateAction<OverlayFilterType | undefined>>
    setIsVisibleOverlay: Dispatch<SetStateAction<boolean>>
    changeOptionItemIsChecked: ChangeOptionItemIsCheckedFn
    applyFilters: ApplyFiltersFn
    resetOptions: ResetOptionsFn
    clearOptions: ClearOptionsFn
  }

  export type OptionGroupConfig = {
    optionGroupName: string
    optionGroupIcon: JSX.Element
    isExclusive: boolean
  }
}

export const initialContext: FilterOverlay.Context = {
  categories: [],
  isVisibleOverlay: false,
  options: INITIAL_OPTIONS,
  currentNode: '',
  isBrandFilter: false,
  brandId: '',
  priceRange: [PRICE_RANGE_DEFAULT.low, PRICE_RANGE_DEFAULT.high],
  sortType: ProductSortType.NONE,
  setPriceRange: () => null,
  priceRangeBoundaries: PRICE_RANGE_DEFAULT,
  currentNodeDepth: 0,
  setIsVisibleOverlay: () => null,
  changeOptionItemIsChecked: () => null,
  currentFilterDrawer: undefined,
  categoryPrefix: undefined,
  isFetchingCategories: false,
  setCurrentFilterDrawer: () => null,
  applyFilters: () => null,
  resetOptions: () => null,
  clearOptions: () => null,
}
