import React from 'react'
import { HighlightBoldText } from '@shopper/ui/src/components/text/new'
import { Button } from 'tamagui'

export interface ApplyFiltersButtonProps {
  onPress?: () => void
  children: React.ReactNode
}

export const ApplyFiltersButton: React.FC<ApplyFiltersButtonProps> = ({ children, onPress }) => {
  const backgroundColor = '$primary1000'
  const borderColor = '$primary1000'
  const textColor = '$pureWhite'

  return (
    <Button
      onPress={onPress}
      flexGrow={1}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      borderRadius={10}
      borderWidth={1}
      hoverStyle={{
        backgroundColor,
        borderColor,
      }}
      pressStyle={{
        backgroundColor,
        borderColor,
      }}
      focusVisibleStyle={{
        outlineStyle: 'none',
      }}
      minHeight={45}
      alignItems="center"
    >
      <HighlightBoldText color={textColor}>{children}</HighlightBoldText>
    </Button>
  )
}
