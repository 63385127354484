import React from 'react'
import { BaseSkeleton } from '@shopper/ui/src/components/BaseSkeleton'
import { XStack } from 'tamagui'

const FeedFilterBarOnly: React.FC = () => {
  return (
    <React.Fragment>
      <XStack
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="#FFFFFF"
        paddingVertical={8}
        overflow="hidden"
      >
        <BaseSkeleton width={94} height={31} borderRadius={24} />
        <BaseSkeleton width={70} height={31} borderRadius={24} />
        <BaseSkeleton width={210} height={31} borderRadius={24} />
      </XStack>
    </React.Fragment>
  )
}

export default FeedFilterBarOnly
