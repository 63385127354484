import type { FilterOverlay } from '@shopper/app/components/Feed/FilterBar/Overlay/context/types'
import isEqual from 'lodash.isequal'

export const changeItemsOnCheck = (
  items: FilterOverlay.OptionItem[],
  isExclusive: boolean,
  target: FilterOverlay.OptionItem,
): FilterOverlay.OptionItem[] => {
  const isChecked = !target.isChecked

  if (isExclusive) {
    return items.map((item) => ({
      ...item,
      ...(isEqual(target._filterItem, item._filterItem) ? { isChecked } : { isChecked: false }),
    }))
  }

  return items.map((item) => ({
    ...item,
    ...(isEqual(target._filterItem, item._filterItem) && { isChecked }),
  }))
}
