import React from 'react'
import { DefaultTextPrimary } from '@shopper/ui/src/components/text'
import { Spacer, Spinner, XStack } from 'tamagui'

export const LittleLoadingFooter: React.FC = () => (
  <XStack>
    <DefaultTextPrimary color="#989898" marginTop="$0.5">
      Cargando
    </DefaultTextPrimary>
    <Spacer size="$2" />
    <Spinner size="small" color="#989898" />
  </XStack>
)

export default LittleLoadingFooter
