import React, { useContext } from 'react'
import { FilterOverlayContext } from '@shopper/app/components/Feed/FilterBar/Overlay/context'
import { ScrollView } from '@shopper/ui/src'
import { CircleFeedButton } from '@shopper/ui/src/components/button/CircleFeedButton'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PATHS from '@centrito/common/paths'

export const CategoriesSliderDisplay: React.FC = () => {
  const { categories, isBrandFilter, brandId } = useContext(FilterOverlayContext)

  return (
    <ScrollView height={85} showsHorizontalScrollIndicator={false} horizontal>
      {categories.map((category) => (
        <CircleFeedButton
          key={`category-browser--${category.nodes}`}
          href={{
            pathname: isBrandFilter
              ? PATHS.Products.ListByBrand
              : PATHS.Products.ListByCategoryPrefix,
            query:
              !isNil(brandId) && !isEmpty(brandId)
                ? { brandId, categoryNodesPrefix: category.nodes }
                : { categoryNodesPrefix: category.nodes },
          }}
          imageSrc={category.assets.tab || ''}
          imageAlt={category.nodes}
          label={category.nodesNamed.split('-').pop() || ''}
          containerHeight={60}
        />
      ))}
    </ScrollView>
  )
}
