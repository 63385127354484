import React from 'react'
import { CaptionStandardText } from '@shopper/ui/src/components/text/new'
import { Building2 } from '@tamagui/lucide-icons'
import { Circle, YStack } from 'tamagui'
import CircleImageWrapper from './CircleImageWrapper/'

type HrefCircleFeedButton =
  | string
  | {
      pathname?: string | null
      query?: {
        categoryNodesPrefix?: string
        listId?: string
        selectedCategory?: string
        brandId?: string
      }
    }
export interface CircleFeedButtonProps {
  href?: HrefCircleFeedButton
  label: string
  imageSrc: string
  imageAlt?: string
  highlighted?: boolean
  hasBackground?: boolean
  onPress?: () => void
  containerHeight?: number
  showLabel?: boolean
}

export const CircleFeedButton: React.FC<CircleFeedButtonProps> = ({
  href = undefined,
  label,
  imageSrc,
  imageAlt = label,
  highlighted = false,
  hasBackground = false,
  onPress = undefined,
  containerHeight = 80,
  showLabel = true,
}) => {
  return (
    <YStack flexGrow={1} flexShrink={1} maxWidth={80}>
      <YStack
        alignItems="center"
        justifyContent="flex-start"
        rowGap={5}
        paddingHorizontal={5}
        height={containerHeight}
        width={72}
        onPress={onPress}
        cursor="pointer"
      >
        <Circle
          borderWidth={highlighted ? 2 : 0}
          borderColor={highlighted ? '$Gray2' : '$pureWhite'}
          borderRadius={10}
        >
          <CircleImageWrapper
            hasBackground={hasBackground}
            href={href}
            imageSrc={imageSrc}
            label={label}
            imageAlt={imageAlt}
            placeholderIcon={<Building2 size={20} />}
          />
        </Circle>
        {showLabel && (
          <CaptionStandardText textAlign="center" color="$neutral700">
            {label}
          </CaptionStandardText>
        )}
      </YStack>
    </YStack>
  )
}
