import React from 'react'
import CircleArrowDownSvg from '@shopper/ui/src/components/svgs/assets/CircleArrowDown'
import CircleArrowUpSvg from '@shopper/ui/src/components/svgs/assets/CircleArrowUp'
import VerTodoSvg from '@shopper/ui/src/components/svgs/assets/VerTodo'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { SolitoImage as Image } from 'solito/image'
import { Link } from 'solito/link'
import { Circle, styled } from 'tamagui'

const ImagePlaceholder = styled(Circle, {
  size: 48,
  width: 48,
  backgroundColor: '$backgroundGrey',
  overflow: 'hidden',
  justifyContent: 'center',
})

type HrefCircleImageWrapper =
  | string
  | {
      pathname?: string | null
      query?: { categoryNodesPrefix?: string; listId?: string; selectedCategory?: string }
    }

interface CircleImageWrapperProps {
  href?: HrefCircleImageWrapper
  label: string
  imageSrc: string
  hasBackground?: boolean
  imageAlt?: string
  placeholderIcon?: React.ReactNode
}

const getImageContent: React.FC<CircleImageWrapperProps> = ({
  label,
  imageSrc,
  imageAlt,
  hasBackground = false,
}) => {
  switch (imageSrc) {
    case 'VerTodo':
      return <VerTodoSvg />
    case 'CircleArrowUp':
      return <CircleArrowUpSvg />
    case 'CircleArrowDown':
      return <CircleArrowDownSvg />
    default:
      if (hasBackground) {
        return (
          <Image
            src={imageSrc}
            alt={imageAlt || label}
            contentFit="contain"
            style={{
              transform: [
                {
                  scale: 0.8,
                },
              ],
            }}
            fill
            contentPosition="center"
          />
        )
      }
      return (
        <Image
          src={imageSrc}
          alt={imageAlt || label}
          contentFit="cover"
          fill
          contentPosition="center"
        />
      )
  }
}

const CircleImageWrapper: React.FC<CircleImageWrapperProps> = ({
  href,
  imageSrc,
  label,
  imageAlt,
  hasBackground,
  placeholderIcon,
}) => {
  const imageContent =
    !isNil(imageSrc) && !isEmpty(imageSrc) ? (
      getImageContent({ hasBackground, label, imageSrc, imageAlt })
    ) : placeholderIcon ? (
      <ImagePlaceholder>{placeholderIcon}</ImagePlaceholder>
    ) : (
      <ImagePlaceholder />
    )

  return !isNil(href) && !isEmpty(href) ? (
    <Link href={href}>
      <ImagePlaceholder>{imageContent}</ImagePlaceholder>
    </Link>
  ) : (
    <ImagePlaceholder>{imageContent}</ImagePlaceholder>
  )
}

export default CircleImageWrapper
