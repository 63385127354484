import React, { useContext } from 'react'
import { FilterOverlayContext } from '@shopper/app/components/Feed/FilterBar/Overlay/context'
import type { FilterOverlay } from '@shopper/app/components/Feed/FilterBar/Overlay/context/types'
import { XStack } from '@shopper/ui/src'
import { ApplyFiltersButton, RemoveFiltersButton } from '@shopper/ui/src/components/button'
import isNil from 'lodash.isnil'
import { GetProductsFilterType } from '@centrito/api/shared/enums'

export const OverlayFooter: React.FC = () => {
  const {
    currentFilterDrawer,
    options,
    priceRange,
    priceRangeBoundaries,
    applyFilters,
    clearOptions,
  } = useContext(FilterOverlayContext)

  const filterType = currentFilterDrawer as FilterOverlay.OverlayFilterType
  const optionItems = options?.[filterType]

  if (isNil(currentFilterDrawer) || isNil(optionItems)) {
    return <React.Fragment />
  }

  if (filterType === GetProductsFilterType.BRAND_ID) {
    return <React.Fragment />
  }

  const canCleanFilters =
    currentFilterDrawer === GetProductsFilterType.PRICE_RANGE
      ? priceRange[0] !== priceRangeBoundaries.low || priceRange[1] !== priceRangeBoundaries.high
      : options[currentFilterDrawer]?.some((option) => option.isChecked)

  return (
    <XStack alignItems="center" gap={15} paddingHorizontal={15} paddingVertical={10}>
      <RemoveFiltersButton onPress={clearOptions} disabled={!canCleanFilters}>
        Limpiar filtro
      </RemoveFiltersButton>
      <ApplyFiltersButton onPress={applyFilters}>Aplicar filtro</ApplyFiltersButton>
    </XStack>
  )
}
