import React from 'react'
import { Stack, XStack, isClient, styled } from '@shopper/ui/src'
import { DetailText1 } from '@shopper/ui/src/components/text/new/detail/DetailText1'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { SolitoImage as Image } from 'solito/image'
import type { BrandPublic, CategoryBasic } from '@centrito/api/nest/platform/database/domain'

export interface PercentageDiscountProps {
  productDiscount: number
  category?: CategoryBasic
  brand?: BrandPublic | null
}

const Container = styled(Stack, {
  backgroundColor: '$brightRed',
  borderTopLeftRadius: 8,
  padding: 3,
  marginLeft: 6,
  marginTop: 7,
  borderBottomRightRadius: 8,
  justifyContent: 'center',
  alignItems: 'center',
})

interface BrandImageProps {
  brandImageUrl: string
  brandName: string
}

const BrandImage: React.FC<BrandImageProps> = ({ brandImageUrl, brandName }) => {
  return (
    <XStack
      backgroundColor="$pureWhite"
      borderWidth={1}
      borderColor="$neutral400"
      alignItems="center"
      margin="auto"
      flexShrink={0}
      borderRadius={5}
      $platform-native={{
        height: 29,
        aspectRatio: 16 / 9,
      }}
      $platform-web={{
        paddingHorizontal: 6,
        paddingVertical: 3.5,
      }}
    >
      {!isClient ? (
        <Image
          fill
          src={brandImageUrl}
          alt={brandName}
          style={{ marginVertical: 3, marginHorizontal: 6 }}
        />
      ) : (
        <Image
          width={50}
          height={20}
          src={brandImageUrl}
          alt={brandName}
          style={{
            aspectRatio: 16 / 9,
            width: '100%',
          }}
        />
      )}
    </XStack>
  )
}

interface DiscountProps {
  discount: number
}

const Discount: React.FC<DiscountProps> = ({ discount }) => {
  return (
    <DetailText1 color="$pureWhite" fontWeight="700">
      -{Math.trunc(discount)}%
    </DetailText1>
  )
}

export const PercentageDiscount: React.FC<PercentageDiscountProps> = ({
  productDiscount,
  category,
  brand,
}) => {
  const isBeautyCategory = category?.nodes.includes('BELLEZA')

  if (productDiscount === 0) {
    if (!isBeautyCategory || isNil(brand) || isEmpty(brand.imageUrl)) {
      return null
    }

    return <BrandImage brandImageUrl={brand.imageUrl} brandName={brand.name} />
  }

  if (isBeautyCategory && !isNil(brand) && !isEmpty(brand.imageUrl)) {
    return (
      <XStack
        overflow="hidden"
        borderRadius={5}
        flexGrow={0}
        flexShrink={1}
        $platform-web={{
          width: 'fit-content',
          background: 'linear-gradient(to right, transparent 5%, #FF004F 5%);',
        }}
        $platform-native={{
          backgroundColor: '$brightRed',
        }}
        alignSelf="flex-start"
      >
        <BrandImage brandImageUrl={brand.imageUrl} brandName={brand.name} />
        <XStack paddingHorizontal={5} paddingVertical={5} marginVertical="auto">
          <Discount discount={productDiscount} />
        </XStack>
      </XStack>
    )
  }

  return (
    <Container>
      <Discount discount={productDiscount} />
    </Container>
  )
}
