import React from 'react'
import FeedOverlay from '@shopper/app/components/Feed/FilterBar/Overlay'
import FilterBarProvider from '@shopper/app/components/Feed/FilterBar/Overlay/context'
import FeedFilterBarPillGroup from '@shopper/app/components/Feed/FilterBar/PillGroup'
import { WrappedRibbon } from '@shopper/app/components/Feed/FilterBar/WrappedRibbon'
import { createParam } from 'solito'

type Params = {
  categoryNodesPrefix: string
  listId: string
  selectedCategory: string
  brandId: string
}

const { useParams } = createParam<Params>()

const FeedFilterBar: React.FC<{
  productsCount?: number
}> = ({ productsCount }) => {
  const { params } = useParams()

  return (
    <FilterBarProvider brandId={params?.brandId} categoryNodesPrefix={params?.categoryNodesPrefix}>
      <FeedOverlay />
      <WrappedRibbon productsCount={productsCount} />
      <FeedFilterBarPillGroup />
    </FilterBarProvider>
  )
}

export default FeedFilterBar
