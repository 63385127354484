import React from 'react'
import { ListPrice } from '@shopper/app/components/ProductDisplay/components/ListPrice'
import { ProductPrice } from '@shopper/app/components/ProductDisplay/components/ProductPrice'
import { Stack, XStack, YStack } from '@shopper/ui/src'
import ShoppingCart from '@shopper/ui/src/components/svgs/assets/ShoppingCart'
import { BodyText3 } from '@shopper/ui/src/components/text/new'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { Link } from 'solito/link'

export interface CompositePriceProps {
  productName: string
  productDiscount: number
  priceRange: {
    low: number | undefined
    high: number | undefined
  }
  isListPrice: boolean
  isPublicDiscount: boolean
  isCartButton?: boolean
  toProductLink?: {
    pathname: string
    query: { [key: string]: string | number }
  }
  retailPrice: number
}

export const CompositePrice: React.FC<CompositePriceProps> = ({
  productName,
  productDiscount,
  priceRange,
  isListPrice,
  isPublicDiscount,
  isCartButton = false,
  toProductLink,
  retailPrice,
}) => {
  return (
    <XStack justifyContent="space-between" alignItems="center" flexShrink={1}>
      <YStack paddingHorizontal={10} flexShrink={1}>
        <BodyText3
          color="$mediumGray"
          fontWeight="400"
          whiteSpace="nowrap"
          numberOfLines={1}
          ellipsizeMode="tail"
          userSelect="none"
          lineHeight={17}
        >
          {productName}
        </BodyText3>

        <XStack minHeight={30} alignItems="center" flexWrap="wrap" columnGap={6}>
          <ProductPrice
            priceRange={priceRange}
            productDiscount={productDiscount}
            isShowingDiscount={!isListPrice && isPublicDiscount}
            retailPrice={retailPrice}
          />
          {!isNil(priceRange.high) && productDiscount != 0 && (
            <ListPrice fullPrice={priceRange.high} fontSize={9} campaign={isListPrice} />
          )}
        </XStack>
      </YStack>
      {isCartButton && !isEmpty(toProductLink) && (
        <Link href={toProductLink}>
          <Stack
            borderWidth={1}
            borderRadius={5}
            padding={4}
            borderColor="#7F7F7F"
            right={5}
            position="absolute"
          >
            <ShoppingCart stroke="#7F7F7F" width={15} height={15} />
          </Stack>
        </Link>
      )}
    </XStack>
  )
}
