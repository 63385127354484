import React from 'react'
import { FilterOverlayContext } from '@shopper/app/components/Feed/FilterBar/Overlay/context'
import type { FilterOverlay } from '@shopper/app/components/Feed/FilterBar/Overlay/context/types'

interface FilterOverlayContextReexposerProps {
  value: FilterOverlay.Context
  children: React.ReactNode
}

export const FilterOverlayContextReexposer: React.FC<FilterOverlayContextReexposerProps> = ({
  children,
  value,
}) => {
  return <FilterOverlayContext.Provider value={value}>{children}</FilterOverlayContext.Provider>
}
