import React from 'react'
import type { FilterOverlay } from '@shopper/app/components/Feed/FilterBar/Overlay/context/types'
import { XStack, YStack } from '@shopper/ui/src'
import { ColorOptionItem } from './Item'

interface ColorGroupProps {
  options: FilterOverlay.OptionItem[]
}

export const ColorGroup: React.FC<ColorGroupProps> = ({ options }) => {
  return (
    <YStack flex={1} paddingBottom={10} paddingHorizontal={15}>
      <XStack flexWrap="wrap" height="100%" justifyContent="flex-start" rowGap={10}>
        {options.map((optionItem) => (
          <ColorOptionItem key={optionItem.name} optionItem={optionItem} />
        ))}
      </XStack>
    </YStack>
  )
}
