import type { GetProductsPublicClient } from '@shopper/app/utils/services/catalog/getProducts'
import isUndefined from 'lodash.isundefined'
import type { GetProducts } from '@centrito/api/shared/catalog'

export default function (
  this: GetProductsPublicClient,
  { page, filters }: GetProducts.Query,
): GetProducts.QueryParams {
  const p = !!page && page > 0 ? `${page}` : undefined
  const { f } = filters ? this.filtersToFiltersQueryParams(filters) : { f: undefined }
  return {
    ...(!isUndefined(p) ? { p } : {}),
    ...(!isUndefined(f) ? { f } : {}),
  }
}
