import React from 'react'
import type { FilterOverlay } from '@shopper/app/components/Feed/FilterBar/Overlay/context/types'
import { YStack } from '@shopper/ui/src'
import { MerchantOptionItem } from './Item'

interface MerchantGroupProps {
  options: FilterOverlay.OptionItem[]
}

export const MerchantGroup: React.FC<MerchantGroupProps> = ({ options }) => {
  return (
    <YStack
      flex={1}
      paddingBottom={10}
      paddingHorizontal={15}
      alignItems="center"
      justifyContent="center"
      gap={15}
    >
      {options.map((optionItem) => (
        <MerchantOptionItem key={optionItem.name} optionItem={optionItem} />
      ))}
    </YStack>
  )
}
