import React, { useEffect, useState } from 'react'
import type { FilterOverlay } from '@shopper/app/components/Feed/FilterBar/Overlay/context/types'
import { Sheet, YStack } from '@shopper/ui/src'
import { SearchInput } from '@shopper/ui/src/components/input'
import { go } from 'fuzzysort'
import { GetProductsFilterType } from '@centrito/api/shared/enums'
import { BrandGroup, CategoryGroup, ColorGroup, MerchantGroup, SizeGroup, SortGroup } from './Group'

interface OptionGroupProps {
  items: FilterOverlay.OptionItem[]
}

const renderBody = (
  options: FilterOverlay.OptionItem[],
  filterType: GetProductsFilterType,
): JSX.Element => {
  if (filterType === GetProductsFilterType.SIZE) {
    return <SizeGroup options={options} />
  }

  if (filterType === GetProductsFilterType.BRAND_ID) {
    return <BrandGroup options={options} />
  }

  if (filterType === GetProductsFilterType.COLOR) {
    return <ColorGroup options={options} />
  }

  if (filterType === GetProductsFilterType.CATEGORY) {
    return <CategoryGroup options={options} />
  }

  if (filterType === GetProductsFilterType.SUPPLIER) {
    return <MerchantGroup options={options.sort((a, b) => a.name.localeCompare(b.name))} />
  }

  if (filterType === GetProductsFilterType.SORT_TYPE) {
    return <SortGroup options={options} />
  }

  return <React.Fragment />
}

const _OptionGroup: React.FC<OptionGroupProps> = ({ items }) => {
  const [searchParameter, setSearchParameter] = useState<string>('')
  const [queriedOptions, setQueriedOptions] = useState<FilterOverlay.OptionItem[]>(items)
  const filterType = items?.[0]?._filterItem?.type ?? GetProductsFilterType.BRAND_ID

  useEffect(() => {
    if (searchParameter !== '') {
      const results = go(searchParameter, items, { key: 'name', threshold: -10000 })
      const matchedItems = results.map((result) => result.obj)

      setQueriedOptions(matchedItems)
      return
    }

    setQueriedOptions(items)
  }, [searchParameter, items])

  return (
    <React.Fragment>
      {items.length > 3 && (
        <YStack paddingHorizontal={15} paddingBottom={10}>
          <SearchInput
            id="search-filters"
            placeholder="Buscar..."
            value={searchParameter}
            onChangeText={setSearchParameter}
          />
        </YStack>
      )}
      <Sheet.ScrollView borderBottomColor="#EDEDED" borderBottomWidth={1}>
        {renderBody(queriedOptions, filterType)}
      </Sheet.ScrollView>
    </React.Fragment>
  )
}

export const OptionGroup = React.memo(_OptionGroup)
